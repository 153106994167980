@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #88C946;
  --color-primary-light-1: #DEF6DA;
  --color-primary-dark: #69A297;
  --color-primary-light: #E8F5DA;
  --color-blue: #00AAE4;
  --color-blue-light: #B5D2FF;
  --color-white: #ffffff;
  --color-orange: #F58A07;
  --color-yellow: #FFCC00;
  --color-yellow-light: #FFFFBF;
  --color-yellow-light-1: #FFDDB5;
  --color-red: #FF274E;
  --color-red-error: #FF0000;
  --color-red-light: #F5CCC8;
  --color-red-light-1: #FFBAB6;
  --color-red-light-2: #FF7D7D;
  --color-dark-pink: #E1448B;
  --color-black: #292929;
  --color-gray: #E0E6EF;
  --color-light-gray: #DEDEDE;
  --color-gray-1: #7C8DA6;
  --color-gray-2: #F0F2F9;
  --color-background-gray: #FAFAFF;
  --color-purple: #9A25B4;
  --color-green-light: #29B9B9;
  --color-black-1: #565656;
  --color-purple-light: #E2E5FF;
  --color-orange-light: #FFEBE2;
  --color-green-light-1: #C8FFDB;
  --color-green-light-2: #B1FFCC;
}

.light {
}

.dark {
}
/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

.customRadio {
  position: relative;
  display: inline-block;
  height: 1.6rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.customRadio input[type="radio"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.customRadio input[type="radio"]:checked + .radioLabel:before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  height: 12px;
  width: 12px;
}

.customRadio .radioLabel:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 6.2px;
  width: 0.7rem;
  height: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.customRadio .radioLabel {
  position: relative;
  cursor: pointer;
  padding-left: 2rem;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #88C946 !important;
}
.react-datepicker-popper{
  position: fixed !important;
}
.rbc-time-view {
  border: none !important;
}
/* .rbc-time-content{

  border: none !important;
} */
.rbc-time-view .rbc-time-header-content, .rbc-row, .rbc-time-header-cell, .rbc-header, .rbc-allday-cell, .rbc-row-bg, .rbc-day-bg {
  border: none !important;
}

.rbc-time-content, .rbc-day-slot.rbc-time-column, .rbc-timeslot-group, .rbc-today, .rbc-time-slot, .rbc-day-slot, .rbc-events-container{
  border-left: none !important;
}

.rbc-time-header-content .rbc-header.rbc-today button{
  background-color: #DEF6DA !important;
}
.rbc-time-header-content .rbc-header.rbc-today{
  background-color: transparent !important;
}

.rbc-allday-cell .rbc-row-bg .rbc-day-bg.rbc-today{
  background-color: transparent !important;
}

.rbc-time-header-content .rbc-header button{
  background-color: #ffffff !important;
  display: block !important;
  text-align: center;
  height: 77px !important;
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #DEDEDE !important;
}
.rbc-time-header-content {
  padding-bottom: 15px !important;
}
.rbc-day-slot.rbc-time-column.rbc-now.rbc-today{
  background-color: transparent !important;
}

.rbc-day-slot.rbc-time-column.rbc-now.rbc-today .rbc-current-time-indicator{
  border: 1px solid #FAFAFF !important;
}

.rbc-label{
  font-size: 14px !important;
  font-weight: 500 !important;
}
.rbc-time-header-content .rbc-allday-cell{
  display: none !important;
}
.rbc-month-view {
  border: none !important;
}
.rbc-row.rbc-month-header{
  height: 40px !important;
}
.rbc-month-row .rbc-day-bg.rbc-today{
  background-color: #DEF6DA !important;
}
.rbc-date-cell button{
  font-weight: 500 !important;
  font-size: 14px !important;
}

.rbc-day-bg.rbc-off-range-bg{
  background-color: #E0E6EF !important;
}

.rbc-time-header.rbc-overflowing{
  border: none !important;
  margin-right: 0px !important;
}
.rbc-overlay{
  background-color: #FAFAFF !important;
  height: 400px;
  min-height: 500px;
  overflow: auto;
}

.rbc-event-label{
  display: none !important;
}
@media (max-width: 600px) {
  .rbc-time-header-content .rbc-header button{
    background-color: transparent !important;
    display: block !important;
    text-align: center;
    height: 20px !important;
    width: 100% !important;
    border-radius: 3px !important;
    border: none !important;
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .rbc-label{
    font-size: 10px !important;
  }  
  .rbc-time-header.rbc-overflowing .rbc-label.rbc-time-header-gutter{
    width: 50px !important;
    min-width: 50px !important;
  }

  .rbc-event-content{
    /* height: 5px !important; */
  }
  /* .rbc-row-bg .rbc-day-bg{
    display: flex !important;
    position: absolute !important;
  } */

}
/* .rbc-month-header{
  background-color: #DEDEDE !important;
  border-bottom: 1px solid #DEDEDE;
  height: 50px;
} */

/* .rbc-header{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEDEDE;
}

.rbc-month-view {
  border: none !important;
  border-radius: 20px !important;
}

.rbc-off-range-bg {
  background: white !important;
}

.rbc-month-row{
  border-radius: 0px 0px 20px 0px;
}

.rdtPicker{
  min-width: 100px !important;
}

.rdtBtn{
  color:#88C946 !important;
}

.rdtCount{
  font-size: 14px !important;
} */

.rbc-date-cell .rbc-button-link{
  font-size: 10px !important;
} 

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
	background-color: white;
}


::-webkit-scrollbar-track {
  background-color: white;
	border-radius: 10px;
   
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
  background-color: var(--color-primary-dark);
}

.rmdp-calendar{
  position: relative;
  z-index: 100px;
}

@media (max-width: 767px) {
  .rbc-overlay{
    background-color: #FAFAFF !important;
    height: 200px;
    min-height: 200px;
    overflow: auto;
  }
}
